<p mat-dialog-title>Personnalisation des cookies</p>
<mat-dialog-content>
    <p>Si vous le souhaitez, vous pouvez choisir les différents cookies :</p>
    <form [formGroup]="customConsent" class="form-container">
        <div class="field">
            <mat-slide-toggle formControlName="gtag" color="primary">
                Google Analytics
            </mat-slide-toggle>
            <span class="description">Contient des informations relatives aux campagnes publicitaires,utilisé pour réguler le taux de requêtes,
                déterminer combien de fois les utilisateurs qui cliquent sur les annonces finissent par accomplir une action
                sur le site.</span>
        </div>
        <div class="field">
            <mat-slide-toggle formControlName="metapixel" color="primary">
                META pixel
            </mat-slide-toggle>
            <span class="description">Identifie les navigateurs pour offrir des services publicitaires et d’analyse de sites.</span>
        </div>
        <div class="field">
            <mat-slide-toggle formControlName="hotjar" color="primary">
                HOTJAR
            </mat-slide-toggle>
            <span class="description">Analyse de sites et comportement durant les sessions utilisateurs afin d'améliorer en continue le site. </span>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-flat-button mat-dialog-close>Fermer</button>
    <button mat-flat-button color="primary" (click)="saveCustomConsent()">Personnaliser</button>
</mat-dialog-actions>