<mat-sidenav-container>
  <mat-sidenav #sidenavl [fixedInViewport]="true" class="w-full">
    <app-core-menu *ngIf="isMobile" (toggleMenu)="toggleMenu()"></app-core-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="sticky">
      <app-navbar (toggleMenu)="toggleMenu()" class="d-print-none"></app-navbar>
    </div>
    <router-outlet></router-outlet>
    <app-footer class="d-print-none"></app-footer>
  </mat-sidenav-content>
  <mat-sidenav #sidenavr position="end" [fixedInViewport]="true" style="width: 100%;">
    <ng-template [cdkPortalOutlet]="portal"></ng-template>
  </mat-sidenav>
</mat-sidenav-container>

<app-tracking-consent></app-tracking-consent>