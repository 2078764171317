import { Component, OnDestroy, ViewChild, ComponentRef } from '@angular/core';
import { PlatformService } from './core/services/platform.service';
import { Subscription } from 'rxjs';
import { HostDirective } from './host.directive';
import { CdkPortalOutletAttachedRef, ComponentPortal, ComponentType, Portal } from '@angular/cdk/portal';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  @ViewChild(HostDirective, { static: true }) hostDirective: HostDirective;

  isMobile: boolean;
  private pltSubscription: Subscription;

  @ViewChild('sidenavl') sidenavl: MatSidenav;
  @ViewChild('sidenavr') sidenavr: MatSidenav;
  portal: Portal<unknown>;
  // componentPortal: ComponentPortal<unknown>;

  constructor(private platformService: PlatformService) {
    this.pltSubscription = this.platformService.getIsMobile().subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
    });
  }

  loadComponentToPortal(component: ComponentType<unknown>, data?: any) {
    this.portal = new ComponentPortal(component);
  }

  toggleMenu() {
    this.sidenavl.toggle();
  }

  toggleRightPan() {
    this.sidenavr.toggle();
  }

  ngOnDestroy() {
    this.pltSubscription.unsubscribe();
  }

}
