<div class="modalOverlay" *ngIf="show">
    <div class="tracking-banner" >
        <div>Ce site web utilise des cookies. Les cookies nous permettent de personnaliser le contenu et les annonces, d'offrir des fonctionnalités aux médias sociaux et d'analyser notre trafic.
            Nous partageons également des informations sur l'utilisation de notre site avec nos partenaires de médias sociaux, de publicité et d'analyse, qui peuvent combiner celles-ci avec d'autres informations
            que vous leur avez fournies ou qu'ils ont collectées lors de votre utilisation de leurs services.
            <a routerLink="/informations/liens-utiles/politique-de-confidentialite-d-oxylio">Politique de confidentialité</a>
        </div>
        <div class="buttons">
            <button mat-button color="primary" class="my-2" (click)="saveConsent('refused')">Continuer sans accepter</button>
            <div class="right">
                <button mat-stroked-button color="primary" class="my-2" (click)="customConsent()">Personnaliser</button>
                <button mat-stroked-button color="primary" class="my-2 ml-2" (click)="saveConsent('accepted')">Accepter et fermer</button>
            </div>
        </div>
    </div>
</div>
