import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { catchError, finalize, Observable, of, share, tap } from 'rxjs';
import { CacheService } from 'src/app/core/services/cache.service';

interface Notification {
  name: string;
  content: string;
  url: string;
  closable: boolean;
  publish: boolean;
  couleur_de_fond: string;
  couleur_de_texte: string;
  taille_police: string;
  graisse_police: string;
  date_debut: string;
  date_fin: string;
}

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrl: './notification-bar.component.scss'
})
export class NotificationBarComponent implements OnInit {

  notifications: Notification[] = [];
  activeNotifications: Notification[] = [];
  constructor(private http: HttpClient, private cacheService: CacheService) {
  
  }

 async ngOnInit() {
    await this.findNotificationBar().subscribe((data: any) => {
      this.notifications = data;

      const now = new Date();
      this.activeNotifications = this.notifications.filter(notification => {
        const dateDebut = notification.date_debut ? new Date(notification.date_debut) : null;
        const dateFin = notification.date_fin ? new Date(notification.date_fin) : null;
        return (!dateDebut || now >= new Date(dateDebut)) && (!dateFin || now <= new Date(dateFin));
      });
    });

   
  }

  findNotificationBar(): Observable<any> {
    const url = '/api/core/notification-bars/';

    const cachedResponse = this.cacheService.get(url);
    if (cachedResponse) {
      return of(cachedResponse);
    }

    const inProgress = this.cacheService.getInProgressObservable(url);
    if (inProgress) {
      return inProgress;
    }

    const request = this.http.get(url)
      .pipe(
        catchError(error => of(console.error(error))),
        tap(response => this.cacheService.set(url, response)),
        finalize(() => this.cacheService.clearInProgressObservable(url)),
        share()
      );

    this.cacheService.setInProgressObservable(url, request);
    return request;
  }

  closeNotification(index: number) {
    this.activeNotifications.splice(index, 1);
  }
}



// {
//   "name": "Test Date",
//     "content": "Test notification activé par date",
//       "closable": true,
//         "publish": true,
//           "couleur_de_fond": "#7f11e0",
//             "couleur_de_texte": "#ffffff",
//               "date_debut": "2024-07-25",
//                 "date_fin": "2024-07-26",
//                   "_id": "66a20a1a1ddfd40019570699",
//                     "__v": 0
// }