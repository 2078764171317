<div *ngFor="let notification of activeNotifications; let i = index"
	class="py-1 px-2 text-center w-full"
	[ngStyle]="{
				'background-color': notification.couleur_de_fond || '#FFFFFF',
				'color': notification.couleur_de_texte || '#000000',
				'font-size': notification.taille_police || '16px',
				'font-weight': notification.graisse_police || '400'
			}"
			[ngClass]="{'hidden': !notification.publish}"
	>
		<div class="flex justify-center items-center">
			<span *ngIf="notification.url">
				<a [href]="notification.url" target="_blank">{{ notification.content }}</a>
			</span>
			<span *ngIf="!notification.url" class="cursor-default">{{ notification.content }}</span>
			<span role="button" *ngIf="notification.closable" class="text-xl ml-3 cursor-pointer" (click)="closeNotification(i)" aria-hidden="true">
				&times;
			</span>
		</div>
</div>