import { Component, OnInit, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { Angulartics2Facebook, Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { TrackingCustomConsentComponent } from '../tracking-custom-consent/tracking-custom-consent.component'

@Component({
    selector: 'app-tracking-consent',
    templateUrl: './tracking-consent.component.html',
    styleUrls: ['./tracking-consent.component.scss']
})
export class TrackingConsentComponent implements OnInit {

    show = false;

    constructor(private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: object,
        @Inject(DOCUMENT) private document: Document, public dialog: MatDialog,
        private angulartics2GoogleGlobalSiteTag: Angulartics2GoogleGlobalSiteTag,
        private angulartics2Facebook: Angulartics2Facebook) { }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            
            //  inject Google Tag Manager
            const s2 = this.document.createElement('script');
            const head = this.document.head;
            s2.type = 'text/javascript';
            s2.async = true;
            s2.defer = true;
            s2.id = 'google-tag-manager';
            s2.innerHTML = `(function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-MX4GZTW');`
            head.appendChild(s2);
            
            // initialize consent mode for google API
            const consentMode = {
                'ad_storage': 'denied',
                'analytics_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
            };
            const s3 = this.document.createElement('script');
            s3.type = 'text/javascript';
            s3.id = 'consentMode';
            s3.innerHTML = `gtag('consent', 'default', ${JSON.stringify(consentMode)});`
            head.appendChild(s3);
            localStorage.setItem('consentMode', JSON.stringify(consentMode));
            
            if (localStorage.getItem('oxylio_tracking') === 'global:allow') {
                this.injectGoogleTagManager();
                this.injectMetaPixel();
                this.injectHotjarTag();
                this.angulartics2GoogleGlobalSiteTag.startTracking();
                this.angulartics2Facebook.startTracking();
                
                const consentMode = {
                    'ad_storage': 'granted',
                    'analytics_storage': 'granted',
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted',
                };
                const s = this.document.createElement('script');
                s.type = 'text/javascript';
                s.id = 'consentMode';
                s.innerHTML = `gtag('consent', 'update',{'ad_storage': 'granted', 'analytics_storage': 'granted', 'ad_user_data': 'granted', 'ad_personalization': 'granted' });`
                const head = this.document.head;
                head.appendChild(s);
                localStorage.setItem('consentMode', JSON.stringify(consentMode));
                
            } else if (localStorage.getItem('oxylio_tracking') === 'global:disallow' || navigator.doNotTrack === '1') {
                this.removeScripts();
            } else if (localStorage.getItem('oxylio_tracking')?.includes('global:custom')) {
                this.customScripts();
                const consentMode = {
                    'ad_storage': 'granted',
                    'analytics_storage': 'granted',
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted',
                };
                const s = this.document.createElement('script');
                s.type = 'text/javascript';
                s.id = 'consentMode';
                s.innerHTML = `gtag('consent', 'update',{'ad_storage': 'granted', 'analytics_storage': 'granted', 'ad_user_data': 'granted', 'ad_personalization': 'granted' });`
                const head = this.document.head;
                head.appendChild(s);
                localStorage.setItem('consentMode', JSON.stringify(consentMode));
            } else {
                this.show = true;
            }
        }
    }

    saveConsent(choice) {
        if (choice === "accepted") {
            this.show = false;
            // its for google tag manager consent à l'avenir il faut faire une fonction dédier au consentMode
            const consentMode = {
                'ad_storage': 'granted',
                'analytics_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
            };
            const s = this.document.createElement('script');
            s.type = 'text/javascript';
            s.id = 'consentMode';
            s.innerHTML = `gtag('consent', 'update',{'ad_storage': 'granted', 'analytics_storage': 'granted', 'ad_user_data': 'granted', 'ad_personalization': 'granted' });`
            const head = this.document.head;
            head.appendChild(s);
            localStorage.setItem('consentMode', JSON.stringify(consentMode));
            
            localStorage.setItem('oxylio_tracking', 'global:allow');
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (window._paq) { _paq.push(["trackEvent", 'e-Privacy', 'accepted']) }
        } else if (choice === 'refused') {
            this.show = false;
            
            // its for google tag manager consent à l'avenir il faut faire une fonction dédier au consentMode
            const consentMode = {
                'ad_storage': 'denied',
                'analytics_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
            };
            const s = this.document.createElement('script');
            s.type = 'text/javascript';
            s.id = 'consentMode';
            s.innerHTML = `gtag('consent', 'update',{'ad_storage': 'denied', 'analytics_storage': 'denied', 'ad_user_data': 'denied', 'ad_personalization': 'denied' });`
            const head = this.document.head;
            head.appendChild(s);
            localStorage.setItem('consentMode', JSON.stringify(consentMode));
            
            localStorage.setItem('oxylio_tracking', 'global:disallow');
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (window._paq) { _paq.push(["trackEvent", 'e-Privacy', 'refused']) }
            this.removeScripts();
        }
    }

    customConsent() {
        const dialog = this.dialog.open(TrackingCustomConsentComponent);
        dialog.afterClosed().subscribe(data => {
            if (data) {
                this.show = false;
                localStorage.setItem('oxylio_tracking', 'global:custom,' + JSON.stringify(data));
                
                // its for google tag manager consent à l'avenir il faut faire une fonction dédier au consentMode
                const consentMode = {
                    'ad_storage': 'granted',
                    'analytics_storage': 'granted',
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted'
                };
                const s = this.document.createElement('script');
                s.type = 'text/javascript';
                s.id = 'consentMode';
                s.innerHTML = `gtag('consent', 'update',{'ad_storage': 'granted', 'analytics_storage': 'granted', 'ad_user_data': 'granted', 'ad_personalization': 'granted' });`
                const head = this.document.head;
                head.appendChild(s);
                localStorage.setItem('consentMode', JSON.stringify(consentMode));
                
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (window._paq) { _paq.push(["trackEvent", 'e-Privacy', 'custom consent']) }
                this.customScripts();
            }
        });
    }

    private customScripts() {
        const keys = JSON.parse(localStorage.getItem('oxylio_tracking').slice(14));
        for (const key in keys) {
            if (Object.prototype.hasOwnProperty.call(keys, key)) {
                if (key === 'gtag') {
                    if (keys[key] === true) {
                        this.injectGoogleTagManager();
                        this.angulartics2GoogleGlobalSiteTag.startTracking();
                    }
                    else {
                        this.renderer.removeChild(this.document.head, this.document.querySelectorAll('#tracking-gtag1'));
                        this.renderer.removeChild(this.document.head, this.document.querySelectorAll('#tracking-gtag2'));
                    }
                }
                if (key === 'metapixel') {
                    if (keys[key] === true) {
                        this.injectMetaPixel();
                        this.angulartics2Facebook.startTracking();
                    }
                    else {
                        this.renderer.removeChild(this.document.head, this.document.querySelectorAll('#tracking-fbp1'));
                        this.renderer.removeChild(this.document.head, this.document.querySelectorAll('#tracking-fbp2'));
                    }
                }
                if (key === 'hotjar') {
                    if (keys[key] === true) {
                        this.injectHotjarTag();
                    }
                    else {
                        this.renderer.removeChild(this.document.head, this.document.querySelectorAll('#tracking-hotjar'));
                    }
                }
            }
        }
    }

    private removeScripts() {
        // for (const iterator of Array.from(this.document.querySelectorAll('#tracking'))) {
        //   this.renderer.removeChild(this.document.head, iterator);
        // }
        this.renderer.removeChild(this.document.head, this.document.querySelectorAll('#tracking-gtag1'));
        this.renderer.removeChild(this.document.head, this.document.querySelectorAll('#tracking-gtag2'));
        this.renderer.removeChild(this.document.head, this.document.querySelectorAll('#tracking-fbp1'));
        this.renderer.removeChild(this.document.head, this.document.querySelectorAll('#tracking-fbp2'));
        this.renderer.removeChild(this.document.head, this.document.querySelectorAll('#tracking-hotjar'));

        this.renderer.removeChild(this.document.body, this.document.getElementsByTagName('app-tracking-consent')[0]);
        
        const consentMode = {
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
        };
        const s = this.document.createElement('script');
        s.type = 'text/javascript';
        s.id = 'consentMode';
        s.innerHTML = `gtag('consent', 'update',{'ad_storage': 'denied', 'analytics_storage': 'denied','ad_user_data': 'denied', 'ad_personalization': 'denied'});`
        const head = this.document.head;
        head.appendChild(s);
        localStorage.setItem('consentMode', JSON.stringify(consentMode));
        
    }

    private injectGoogleTagManager() {
        const s1 = this.document.createElement('script');
        s1.type = 'text/javascript';
        s1.async = true;
        s1.id = 'tracking-gtag1';
        s1.src = 'https://www.googletagmanager.com/gtag/js?id=G-BHRW725M53';
        const head = this.document.head;
        head.appendChild(s1);
        const s2 = this.document.createElement('script');
        s2.type = 'text/javascript';
        s2.id = 'tracking-gtag2';
        s2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-BHRW725M53');
      gtag('config', 'AW-768278229');`;
        head.appendChild(s2);
    }

    private injectMetaPixel() {
        const s1 = this.document.createElement('script');
        s1.type = 'text/javascript';
        s1.defer = true;
        s1.id = 'tracking-fbp1';
        s1.src = 'https://connect.facebook.net/en_US/fbevents.js';
        const head = this.document.head;
        head.appendChild(s1);
        const s2 = this.document.createElement('script');
        s2.type = 'text/javascript';
        s2.id = 'tracking-fbp2';
        s2.innerHTML = `
      !function (f, n) {
        if (f.fbq) return; n = f.fbq = function () { n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments) };
        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = [];
      }(window);
      fbq('init', '191212264952386');
      fbq('track', 'PageView');`;
        head.appendChild(s2);
    }

    private injectHotjarTag() {
        const s = this.document.createElement('script');
        s.type = 'text/javascript';
        s.defer = true;
        s.id = 'tracking-hotjar';
        s.innerHTML = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2017672,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
        const head = this.document.head;
        head.appendChild(s);
    }
}