import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-tracking-custom-consent',
  templateUrl: './tracking-custom-consent.component.html',
  styleUrls: ['./tracking-custom-consent.component.scss']
})
export class TrackingCustomConsentComponent {

  customConsent = this.fb.group({
      gtag: [false],
      metapixel: [false],
      hotjar: [false]
    });

  constructor(private dialogRef: MatDialogRef<TrackingCustomConsentComponent>, private fb: UntypedFormBuilder) { }

  saveCustomConsent() {
    this.dialogRef.close(this.customConsent.value);
  }
}
