import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { SearchService } from '../services/search.service';
import { firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { InfoPopupComponent } from '../info-popup/info-popup.component';
import { isPlatformBrowser } from '@angular/common';
import { FilteringService } from 'src/app/search/filtering.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  marques: string[];
  copyright: number;

  iconsReinsurance = [
    {
      name: 'Paiement en ligne  100% sécurisé',
      icon: 'paiement.svg'
    },
    {
      name: 'Satisfait ou remboursé sous 15j',
      icon: 'satisfait-rembourse.svg'
    },
    {
      name: 'Livraison à domicile ou en concession',
      icon: 'livraison.svg'
    },
    {
      name: 'Véhicules révisés et garantis 12 mois',
      icon: 'garanties.svg'
    }
  ];

  constructor(private searchService: SearchService,
    public dialog: MatDialog, @Inject(PLATFORM_ID) private platformId: object,
    private filteringService: FilteringService) { }

  async ngOnInit() {
    try {
      this.marques = (await firstValueFrom(this.searchService.search({}, 'footer'))).marques;
    } catch (error) { console.error(error); }
    this.copyright = new Date().getFullYear();
    // if (isPlatformBrowser(this.platformId)) {
    //   this.infoPopup();
    // }
  }

  setFiltersMarque(filter) {
    this.filteringService.storeFilters = { marques: filter };
    this.filteringService.getVehicles();
  }
    
    setFiltersType(filter) {
        this.filteringService.storeFilters = { statut_vehicule_vu: filter };
        this.filteringService.getVehicles();
    }

  trackingConsentModification() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('oxylio_tracking');
      window.location.reload();
    }
  }

  // infoPopup() {
  //   this.dialog.open(InfoPopupComponent, {
  //     autoFocus: false,
  //     // closeOnNavigation: true
  //   });
  // }


  setFiltersCarburants(filter) {
    this.filteringService.storeFilters = { carburants: filter };
    this.filteringService.getVehicles();
  }

  setFiltersOffers(filter) {
    this.filteringService.storeFilters = { offers: filter };
    this.filteringService.getVehicles();
  }

  setFilterPetitsPrix() {
    this.filteringService.storeFilters = { petits_prix: 1 };
    this.filteringService.getVehicles();
  }

  setFilterFaibleKm() {
    this.filteringService.storeFilters = { faibles_km: 1 };
    this.filteringService.getVehicles();
  }
  setFilterOccasionsRecentes() {
    this.filteringService.storeFilters = { occasions_recentes: 1 };
    this.filteringService.getVehicles();
  }

}
